@import '../../../variables';

.student-notebook {
    display: flex;
    flex: 0 0 62px;
    border-bottom: 1px solid $color-border-gray;
    align-items: center;
    cursor: pointer;
    transition: $standard-hover-transition;

    &:hover {
        background-color: rgba($color-primary-green, 0.05);
    }

    h2 {
        font-size: 19px;
        color: $color-primary-green;
    }

    .portrait,
    .portraitPlaceholder {
        width: 42px;
        height: 42px;
        margin: 0 14px;
        border-radius: 50%;
        background: url('../../../assets/images/no-profile-picture.png')
            no-repeat 50% / cover;
        object-fit: cover;
    }

    .newIndicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: 26px;
        background-color: grey;
    }
}
