@import '../../../../../variables';

.entry-preview {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 80px;
  padding: 16px 14px;
  font-size: 13px;
  background-color: white;

  .entry-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .icons-and-actions {
      display: flex;
      align-items: center;
    }
  }

  &:hover {
    background-color: rgba($color-primary-green, 0.05);
  }

  &.is-reply {
    padding: 12px 6px 12px 18px;

    .author-name {
      font-size: 14px;
    }

    .date {
      font-size: 12px;
    }

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }

    &:hover {
      background-color: white;
    }
  }

  .row {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-self: stretch;
    justify-content: space-between;
  }

  .author-name {
    font-size: 15px;
    margin: 0;
  }

  .date {
    padding-left: 1px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.32);
  }

  .text {
    display: inline-block;
    width: 100%;
    margin-top: 6px;
    color: $color-text-dark;
    overflow: hidden;
  }

  .likert {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    span {
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      margin: 10px 17px 0 0;
      color: #555;

      img {
        position: relative;
        top: 1px;
        margin-left: 8px;
        height: 22px;
      }
    }
  }
}
