html {
  display: flex;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

body {
  display: flex;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: none;
  background-color: #ebeeef;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

h1, h2 {
  font-family: 'Varela Round';
  font-weight: 400;
}

h3, h4, h5, h6, a, button {
  font-weight: 600;
}

ul {
  list-style: none;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input {
  font-size: 16px;
}

input:focus {
  /* $color-primary-green (in variables) */
  border-color: #07bd87 !important;
}

button {
  border: none;
}

#root {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.05);

  /* $site-max-width (in variables) */
  max-width: 620px;
}
