@import '../../variables';

.icons {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  font-size: 12px;
  margin-top: 2px;
  margin-left: auto;

  .icon {
    display: flex;
    justify-content: center;

    svg {
      fill: $color-icon-gray;
    }

    .icon-count {
      font-weight: 600;
      color: #7b8187;
    }

    &.comment-bubble {
      margin-left: 12px;

      svg {
        margin-right: 3px;
      }
    }

    &.paperclip svg {
      height: 16px;
      margin-right: 2px;
      transform: rotate(5deg);
    }

    &.thumbs-up {
      margin-left: 8px;
      cursor: pointer;

      &.selected svg {
        fill: $color-primary-green;
      }

      .icon-count {
        margin-left: -1px;
      }

      svg {
        height: 15px;
      }

      &.no-pointer {
        cursor: not-allowed;
      }
    }
  }

  &.existing-entry {

    .icon {

      &.comment-bubble {
        display: none;
      }
  
      &.paperclip {
        display: none;
      }
  
      &.thumbs-up {
        margin-right: 6px;
  
        svg {
          height: 19px;
          margin: -2px 4px 0 0;
        }
      }
    }
  }
}
