// Colors
$color-text-dark: #2c3032;
$color-text-med: #54646c;

$color-primary-green: #07bd87;
$color-primary-blue: #50b4ef;
$color-primary-yellow: #d68e24;
$color-primary-purple: #9b51e0;

$color-primary-green-hover: #01a272;
$color-disabled-green-button: #e8eceb;

$color-primary-blue-hover: #45a4da;

$color-icon-gray: #a6afb8;
$color-border-gray: #d7dde0;

// Spacing
$existing-note-margin: 24px;
$site-max-width: 620px;
$gutter-width: 15px;

// Transitions
$standard-hover-transition: all 0.3s;

// Other
$main-button-padding: 0.8em 1.9em;

// Mixins
@mixin standard-button-styles {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8em 2.1em;
    border: none;
    background: $color-primary-green;
    border-radius: 40px;
    color: white;
    transition: $standard-hover-transition;
    cursor: pointer;

    &:hover {
        background-color: $color-primary-green-hover;
    }
}

@mixin selected-indicator-styles {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 58px;
    border: 1px solid $color-border-gray;
    border-radius: 4px;
    font-size: 15px;

    img {
        margin-left: 10px;
    }
}
