@import "../../../../variables";

.container {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 90px;
    background: white;

    .entry-content {
        width: 100%;
        padding: 0 15px;
    }

    .header-divider {
        width: calc(100% - 30px);
        margin: 24px 0;
        height: 1px;
        background: $color-border-gray;
    }

    .header {
        position: relative;
        z-index: 2;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        > :last-child {
            transform: scale(1);

            > div {
                transform: scale(1);
            }
        }

        .back-button-wrapper {
            position: absolute;
            top: -111px;
            left: 0;
        }

        .title {
            display: flex;
            text-align: left;
            flex-direction: column;

            .author {
                font-size: 17px;
                line-height: 20px;
            }

            .timestamp {
                padding-left: 1px;
                font-weight: 600;
                font-size: 13px;
                line-height: 17px;
                color: rgba(0, 0, 0, 0.32);
            }
        }

        .entry-actions {
            display: flex;
            align-items: center;
        }
    }

    .scroll {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: visible;

        .note {
            padding-top: 20px;
            font-size: 15px;
            line-height: 140%;
            white-space: pre-wrap;
        }

        .attachments {

            > * {
                display: flex;
                flex-flow: column;
                margin-top: $existing-note-margin;
            }

            .image-attachments li {
                margin-top: 12px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .replies {
            margin: $existing-note-margin 0 0 8px;
            border-left: 2px solid $color-border-gray;
        }
    }

    .reply-container {
        $reply-contents-height: 36px;

        position: fixed;
        z-index: 2;
        bottom: 0;
        width: 100%;
        max-width: $site-max-width;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border-top: 1px solid $color-border-gray;
        background-color: white;
        box-shadow: 0 -6px 14px -5px rgb(0 0 0 / 8%);

        .reply-input {
            border: 1px solid #dedede;
            box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
            border-radius: $reply-contents-height;
            width: 100%;
            height: $reply-contents-height;
            margin-top: auto;
            padding: 0 1rem;
        }

        .reply-button {
            height: $reply-contents-height;
            width: $reply-contents-height;
            border-radius: 50%;
            flex-shrink: 0;
            margin-left: 7px;

            background-image: url("../../../../assets/images/check.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 17px 12px;
            transition: $standard-hover-transition;

            // disabled styles
            cursor: not-allowed;
            background-color: $color-disabled-green-button;
            box-shadow: none;

            &.active {
                cursor: pointer;
                background-color: $color-primary-green;
                box-shadow: 2px 3px 20px -5px rgba($color-primary-green, 0.6);
            }
        }
    }

    .likert {
        margin-top: 20px;

        .selected-indicator {
            margin-top: 8px;
            @include selected-indicator-styles
        }
    }
}
