@import '../../../../../../variables';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid $color-border-gray;

    .email {
        font-size: 13px;
        color: $color-text-dark;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 12px;
    }

    .actions {
        display: flex;
        align-items: center;
    }

    .status {
        display: flex;
        gap: 10px;
        .active {
            display: flex;
            flex-shrink: 0;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $color-primary-green;
            align-items: center;

            &::before {
                display: inline-flex;
                content: '';
                width: 16px;
                height: 16px;
                margin-right: 4px;
                border-radius: 50%;
                background-color: $color-primary-green;
                background-image: url('../../../../../../assets/check2.svg');
                background-size: 8px 7px;
                background-repeat: no-repeat;
                background-position: 50% 4px;
            }
        }

        .resend {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: rgba(80, 180, 239, 1);
            cursor: pointer;
        }
        .resent {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: grey;
        }

        .pending {
            display: flex;
            flex-shrink: 0;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $color-primary-yellow;
            align-items: center;

            &::before {
                display: inline-flex;
                content: '';
                width: 16px;
                height: 16px;
                margin-right: 4px;
                border-radius: 50%;
                background-color: $color-primary-yellow;
                background-image: url('../../../../../../assets/exclamation.svg');
                background-size: 3px 9px;
                background-repeat: no-repeat;
                background-position: 7px 50%;
            }
        }
    }
    .remove {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
        background-color: rgba($color-text-med, 0.4);
        transition: $standard-hover-transition;

        svg {
            color: white;
        }

        &:hover {
            background-color: rgba($color-text-med, 0.7);
        }
    }
}

// Modal Styles - should make component

.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: $site-max-width;
    margin: 0 auto;
    background-color: rgba($color-primary-blue, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;

    .confirm-remove {
        position: relative;
        width: calc(100% - #{$gutter-width * 2});
        max-width: 400px;
        padding: 40px;
        border-radius: 8px;
        text-align: center;
        background-color: white;

        .remove {
            position: absolute;
            top: 12px;
            right: 12px;
        }

        .button-row {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                margin-top: 18px;
                @include standard-button-styles;

                &.cancel {
                    background-color: rgba($color-text-med, 0.4);

                    &:hover {
                        background-color: rgba($color-text-med, 0.7);
                    }
                }

                &:last-child {
                    margin-left: 8px;
                }
            }
        }
    }
}
