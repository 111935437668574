@import '../../../../../variables';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .simple-subheader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - #{$gutter-width * 2});
    margin: 0 auto;
    padding: 18px 0;
    border-bottom: 1px solid $color-border-gray;

    .back-button-wrapper > div {
      top: 15px;
      left: 0;
    }

    .title {
      justify-content: center;
      font-size: 20px;
      color: $color-text-dark;
    }
  }

  .family-invites {
    margin: 0 $gutter-width;
  }

  .invite {
    padding: 26px 0 16px;

    .row {
      display: flex;
    }

    input {
      display: flex;
      flex: 1 0 auto;
      border: 1px solid $color-border-gray; 
      border-radius: 4px;
      padding: 0 10px;
    }

    .add-invite {
      margin-left: 10px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 2px 3px 20px -5px rgba($color-primary-green, 0.6);
      background-image: url('../../../../../assets/plus.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px 14px;
      background-color: $color-primary-green;
      transition: $standard-hover-transition;
      
      &.disabled {
        cursor: not-allowed;
        background-color: $color-disabled-green-button;
        box-shadow: none;

        &:hover {
          background-color: $color-disabled-green-button;
        }
      }

      &:hover {
        background-color: $color-primary-green-hover;
      }
    }
  }

  .invites {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
}
