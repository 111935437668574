@import '../../variables';

.container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgb(105, 179, 234);
  background: url('../../assets/images/scribble-blue.jpg');
  background-size: 650px;
  align-items: center;
  justify-content: center;

  .box {
    max-width: 450px;
    width: 90%;

    .logo {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      img {
        margin: 15px 4px;
      }
    }

    form {
      display: flex;
      flex-direction: column;

      width: 100%;
      padding: 40px 29px;
      background: white;
      border-radius: 8px;

      h2 {
        font-size: 26px;
        margin-bottom: 20px;
        text-align: center;
        color: $color-text-dark;
      }

      p {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
      }

      input {
        height: 38px;
        border: 1px solid $color-border-gray; 
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 0 12px;
        font-weight: 500;
        line-height: 18px;
      }

      button {
        margin: 10px auto;
        @include standard-button-styles;
      }

      a {
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: $color-primary-green;
      }
    }
  }
}
