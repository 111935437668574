@import '../../../variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  width: 100%;
  height: 100%;
  background-color: white;

  .notebook-switcher-container {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    padding: 18px 0 25px;
    background-color: white;
    border-bottom: 1px solid $color-border-gray;
    box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
  }

  .notebook-switcher {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 0px 0px 2px;
    width: 200px;
    height: 34px;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 36px;

    div {
      /* Auto Layout */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1 0 auto;
      height: 30px;
      border-radius: 21px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #969c9e;
      cursor: pointer;
      transition: all 0.4s ease;

      &:hover {
        color: $color-text-med;
      }

      &.active {
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
        cursor: default;
      }

      &:nth-child(1) {
        &.active {
          color: rgba(7, 189, 135, 1);
        }
      }

      &:nth-child(2) {
        &.active {
          color: rgba(214, 142, 36, 1);
        }
      }
    }
  }
}
