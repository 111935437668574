@import '../../_variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/scribble-lightblue.jpg');
  background-size: 650px;

  .title {
    z-index: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0;
    font-size: 22px;
    color: $color-text-dark;
    border-bottom: 1px solid $color-border-gray;
    background-color: white;
    box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
  }

  .notebooks {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
  }
}
