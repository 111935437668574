@import "../../variables";

.header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;

  .banner {
    position: relative;
    display: flex;
    height: 46px;
    width: 100%;
    padding: 0 15px;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(105, 179, 234);
    background: url('../../assets/images/scribble-blue.jpg');
    background-size: 400px;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        height: 26px;

        @media(max-width: 370px) {
          height: 22px;
        }
      }
    }

    .portrait,
    .portrait-placeholder {
      $portrait-size: 70px;

      z-index: 2;
      position: absolute;
      left: 50%;
      top: 10px;
      margin-left: -$portrait-size/2;
      width: $portrait-size;
      height: $portrait-size;
      border-radius: 50%;
      border: 4px solid white;
      object-fit: cover;
      cursor: pointer;
    }

    .portrait-placeholder {
      background: url('../../assets/images/no-profile-picture.png')
          no-repeat 50% / cover;
      object-fit: cover;
    }

    .menuIcon {
      display: flex;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #fff;
      background-image: url('../../assets/person.svg');
      background-size: 10px 14px;
      background-repeat: no-repeat;
      background-position: 50%;
      cursor: pointer;
    }

    .menu {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 40px;
      right: 0;
      align-items: flex-end;
    }
  }

  .subheader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: calc(100% - #{$gutter-width * 2});
    padding-top: 38px;
    background-color: white;

    &.no-photo {
      padding-top: 0;
      border-bottom: 1px solid $color-border-gray;

      > *:first-child {
        top: auto;
      }

      h1 {
        padding: 18px 0;
      }
    }

    .name {
      position: relative;
      right: -2px;
      display: flex;
      align-items: center;
      margin: auto;
      font-size: 20px;
      color: $color-primary-blue;
      text-align: center;
      cursor: pointer;
    }
  }
}

.profile-arrow-wrapper {
  position: absolute;
  top: 0;
  right: 30px;
  
  > div {
    transform: rotate(180deg);
  }
}
