@import '../../variables';

.image-container {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 5px;
  border: 1px solid $color-border-gray; 
  border-radius: 4px;

  img {
    min-width: 50%;
    max-width: 100%;
    align-self: flex-start;
    border-radius: 2px;
  }
}

.attachment {
  position: relative;
  display: flex;
  flex-flow: row !important;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background-color: #f2f3f4;
  border-radius: 6px;
  margin-bottom: 8px;
  padding: 0 10px 0 38px;
  font-size: 13px;
  color: #828282;
  font-weight: 600;

  &::before {
    position: absolute;
    content: "";
    width: 9px;
    height: 18px;
    left: 17px;
    top: 15px;
    background: url('../../assets/paperclip.svg');
    background-size: 9px 18px;
    background-repeat: no-repeat;
  }

  .download-attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 4px;
    background-color: rgba(0,0,0,.06);
    transition: $standard-hover-transition;
    flex-shrink: 0;
    margin-left: 5px;

    &:hover {
      background-color: rgba(0,0,0,.11);
    }
  }

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.remove {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  background-color: rgba(196, 196, 196, 1);
  cursor: pointer;
}
