@import '../../variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  .simple-subheader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - #{$gutter-width * 2});
    padding: 18px 0;
    border-bottom: 1px solid $color-border-gray;

    .back-button-wrapper > div {
      top: 15px;
      left: 0;
    }

    .title {
      justify-content: center;
      font-size: 20px;
      color: $color-text-dark;
    }

    .logout {
      position: absolute;
      top: 20px;
      right: 0;
      font-weight: 600;
      font-size: 13px;
      color: $color-primary-blue;
      cursor: pointer;
      flex-shrink: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 14px;

    .row {
      display: flex;
      flex-direction: row;
      margin: 12px 0;

      .input-label {
        font-family: 'Varela Round';
        margin-bottom: 5px;
        font-size: 14px;
      }

      label {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-self: flex-end;
        margin-right: 10px;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);

        &:last-child {
          margin-right: 0;
        }
      }

      input {
        display: flex;
        height: 38px;
        width: 100%;
        border: 1px solid $color-border-gray; 
        padding: 0 10px;
        border-radius: 4px;
        color: $color-text-dark;
      }

      a,
      a:visited {
        font-size: 13px;
        line-height: 16px;
        color: $color-primary-green;
        text-decoration: none;
        font-family: 'Varela Round';

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 18px 0 0 0;
    @include standard-button-styles;
      
    &.disabled {
      cursor: not-allowed;
      background-color: $color-disabled-green-button;
      box-shadow: none;

      &:hover {
        background-color: $color-disabled-green-button;
      }
    }

    &:hover {
      background-color: $color-primary-green-hover;
    }

    &.cancel {
      background-color: rgba($color-text-med, 0.4);
      
      &:hover {
        background-color: rgba($color-text-med, 0.7);
      }
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.error {
  color: red;
  font-size: 14px;
}

// Change Password page

.title {
  margin-top: 40px;
  font-size: 20px;
}
