@import '../../variables';

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgb(162 169 179 / 14%);
  transition: $standard-hover-transition;
  cursor: pointer;
  transform: scale(0.85);
  position: relative;
  z-index: 1;
  
  .menu {
    transform: scale(1.05); 
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35px;
    right: 0;
    width: 110px;
    background: #ffffff;
    box-shadow: 0px 1px 7px rgb(0 0 0 / 17%);
    border-radius: 4px;
    z-index: 1;
    background: white;
    padding: 8px;
    font-size: 13px;

    span {
      padding: 4px 8px;
      color: $color-text-med;
      font-weight: 600;
      transition: $standard-hover-transition;
      border-radius: 3px;
      cursor: pointer;
      
      &:hover {
        background-color: #f0f1f3;
      }
    }
  }

  &:hover {
    background-color: rgb(162 169 179 / 25%);
  }
}
