@import '../../variables';

.backButton {
  position: absolute;
  top: 15px;
  left: 0;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url('../../assets/images/backarrow.svg') center / 16px 12px no-repeat rgba($color-primary-blue, 0.1);
  cursor: pointer;
  transition: $standard-hover-transition;

  &:hover {
    background-color: rgba($color-primary-blue, 0.2);
  }
}
