@import "../../../../variables";

$section-padding: 20px;

.student-profile-container {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 100%;

    h2 {
        font-size: 17px;
        margin-bottom: 10px;

        &.family-header {
            color: $color-primary-green;
        }

        &.teachers-header {
            color: $color-primary-yellow;
        }

        &.student-progress {
            color: $color-primary-green;
        }

        &.attachments-images {
            color: $color-primary-purple;
        }
    }

    section {
        padding: $section-padding 0;
        margin: 0 $gutter-width;
        border-bottom: 1px solid $color-border-gray;

        &:last-child {
            border-bottom: none;
        }
    }

    .photoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .placeholderImage,
        img {
            width: 128px;
            height: 128px;
            border-radius: 50%;
            background-color: #eee;
            object-fit: cover;
        }

        .placeholderImage {
            background: url('../../../../assets/images/no-profile-picture.png')
                no-repeat 50% / cover;
            object-fit: cover;
        }

        .edit {
            margin-top: 7px;
            font-size: 14px;
            font-family: "Varela Round";
            color: $color-primary-blue;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .family-teachers {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;

        .row {
            display: flex;
            flex-shrink: 0;
            flex-direction: row;
        }

        .column {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            font-size: 14px;
            max-width: calc(50% - 6px);

            h2 {
                margin-bottom: 6px;
            }

            &:first-child {
                margin-right: 12px;
            }

            .person {
                color: $color-text-med;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 7px;

                &:last-child {
                    margin-bottom: 4px;
                }
            }
        }

        .add {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            font-size: 13px;
            font-family: "Varela Round";
            margin: 10px 0 8px;
            padding: 5px 16px;
            border: 0;
            background-color: $color-primary-green;
            border-radius: 30px;
            color: rgba(255, 255, 255, 1);
            cursor: pointer;
            transition: $standard-hover-transition;

            &:hover {
                background-color: $color-primary-green-hover;
            }

            svg {
                margin-right: 7px;
            }
        }
    }

    .progressContainer {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;

        .document {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 66px;
            background-color: rgba(242, 243, 244, 1);
            border-radius: 6px;
            margin-bottom: 8px;
            padding: 0 11px 0 15px;
            color: rgba(130, 130, 130, 1);
            font-weight: 600;
            font-size: 14px;

            .download-pdf {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba($color-primary-green, 0.07);
                height: 44px;
                width: 44px;
                border-radius: 4px;
                transition: $standard-hover-transition;
                cursor: pointer;

                svg {
                    color: $color-primary-green;
                }

                &:hover {
                    background-color: rgba($color-primary-green, 0.13);
                }
            }

            &.active {
                background-color: rgba(240, 251, 248, 1);

                .title {
                    color: $color-primary-green;
                }
            }
        }

        .date {
            font-size: 12px;
        }
    }

    .attachments {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;

        .attachment {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            width: 100%;
            background-color: #f2f3f4;
            border-radius: 6px;
            margin-bottom: 6px;
            padding: 0 10px 0 38px;
            font-size: 13px;
            color: #828282;
            font-weight: 600;

            &:last-of-type {
                margin-bottom: 12px;
            }

            &::before {
                position: absolute;
                content: "";
                width: 9px;
                height: 18px;
                left: 17px;
                top: 15px;
                background: url("../../../../assets/paperclip.svg");
                background-size: 9px 18px;
                background-repeat: no-repeat;
            }

            .download-attachment {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                height: 30px;
                width: 30px;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.06);
                transition: $standard-hover-transition;
                flex-shrink: 0;
                margin-left: 5px;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.11);
                }
            }

            .name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .imageAttachments {
        display: flex;
        flex-wrap: wrap;

        > div {
            height: 0;
            flex-basis: calc(33.33% - 8px);
            padding-bottom: calc(33.33% - 8px);
            position: relative;
            margin: 0 12px 12px 0;

            &:nth-child(3n) {
                margin-right: 0;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }

    .delete-notebook {
        padding-bottom: $section-padding;

        button {
            margin: 10px auto;
            @include standard-button-styles;
        }
    }
}

// Modal Styles - should make component

.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: $site-max-width;
    margin: 0 auto;
    background-color: rgba($color-primary-blue, 0.7);
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    .confirm-remove {
      position: relative;
      width: calc(100% - #{$gutter-width*2});
      max-width: 400px;
      padding: 40px;
      border-radius: 8px;
      text-align: center;
      background-color: white;

      .remove {
        position: absolute;
        top: 12px;
        right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        margin-left: 10px;
        cursor: pointer;
        background-color: rgba($color-text-med, 0.4);
        transition: $standard-hover-transition;
    
        svg {
          color: white;
        }
    
        &:hover {
          background-color: rgba($color-text-med, 0.7);
        }
      }
  
      .button-row {
        display: flex;
        align-items: center;
        justify-content: center;
  
        button {
          margin: 18px 0 0 0;
          @include standard-button-styles;
  
          &.cancel {
            background-color: rgba($color-text-med, 0.4);
            
            &:hover {
              background-color: rgba($color-text-med, 0.7);
            }
          }
  
          &:last-child {
            margin-left: 8px;
          }
        }
      }
    }
  }
