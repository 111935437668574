@import '../../../../variables';

.entry-list-wrapper {
    padding-bottom: 74px;
    overflow-y: scroll;
    width: 100%;
}

.entry-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100vw;
    max-width: 100%;
    background-color: white;

    .no-entries {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        color: $color-text-med;
        font-size: 15px;
    }

    .add {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        right: 12px;
        bottom: 12px;
        cursor: pointer;
        box-shadow: 2px 3px 20px -5px rgba($color-primary-blue, 0.6);
        background-image: url('../../../../assets/plus.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px 14px;
        background-color: $color-primary-blue;
        transition: $standard-hover-transition;

        &:hover {
            background-color: $color-primary-blue-hover;
        }
    }
}
