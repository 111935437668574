@import '../../../../variables';

$indicators-bar-height: 126px;

.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  margin-top: -30px;
  padding: 0 $gutter-width;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    padding-bottom: calc(#{$indicators-bar-height} + 16px);

    .topControls {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 6px;
      position: relative;
      z-index: 1;

      .cancel {
        margin-right: auto;
        color: rgba(80, 180, 239, 1);
        font-weight: 600;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .upload-button {
        margin-right: 8px;
        cursor: pointer;
        height: 33px;
        width: 33px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $standard-hover-transition;

        &:hover {
          background-color: rgba($color-primary-green, 0.1);
        }

        input {
          display: none;
        }

        svg {
          color: $color-primary-green;
        }
      }

      .save {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-image: url('../../../../assets/images/check.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px 14px;
        transition: $standard-hover-transition;

        // disabled styles
        cursor: not-allowed;
        background-color: $color-disabled-green-button;
        box-shadow: none;

        &.active {
          cursor: pointer;
          background-color: $color-primary-green;
          box-shadow: 2px 3px 20px -5px rgba($color-primary-green, 0.6);
        }
      }
    }

    .text {
      margin-top: 10px;
      position: relative;
      width: 100%;

      textarea {
        padding-top: 10px;
        width: 100%;
        border: none;
        resize: none;
        font-size: 16px;
        color: $color-text-dark;
        min-height: 54px;
      }
    }

    .error {
      color: red;
    }

    .selected-indicator,
    .image-container,
    .attachment {
      margin-top: 14px;
    }

    .attachments {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      .image-container {
        display: flex;
        position: relative;
        padding: 5px;
        border: 1px solid $color-border-gray; 
        border-radius: 4px;

        img {
          max-width: 100%;
          align-self: flex-start;
        }
      }

      .attachment {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 0 0 44px;
        width: 100%;
        background-color: #f2f3f4;
        border-radius: 6px;
        padding-left: 37px;
        padding-right: 2px;
        font-size: 13px;
        color: #828282;
        font-weight: 600;
        cursor: pointer;

        &::before {
          position: absolute;
          content: "";
          width: 9px;
          height: 18px;
          left: 17px;
          top: 13px;
          background: url('../../../../assets/paperclip.svg');
          background-size: 9px 18px;
          background-repeat: no-repeat;
        }
      }
    }

    .selected-indicator {
      @include selected-indicator-styles
    }

    .remove {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -12px;
      right: -12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      background-color: rgba(196, 196, 196, 1);
      cursor: pointer;
    }

    select {
      height: 40px;
      border: 1px solid $color-border-gray;
      padding: 0 1em;
      font-size: 14px;
      border-radius: 5px;
      margin: 20px 60px 12px;
      max-width: 300px;
      display: flex;
      width: 100%;
      flex-grow: 1;

      // hide default arrow icon
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: url('../../../../assets/images/dropdown-arrow.svg') no-repeat;
      background-size: 12px 7px;
      background-position: right 16px center;
      transition: $standard-hover-transition;
      cursor: pointer;

      &:hover {
        border-color: #bcc2c6;
        background-color: #f8fafa;
      }

      &:focus {
        border-color: $color-primary-green;
      }
    }

    .indicators {
      position: fixed;
      width: 100%;
      max-width: $site-max-width;
      margin-left: -$gutter-width;
      bottom: 0;
      height: 126px;
      background-color: white;
      border-top: 1px solid $color-border-gray;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto;

      .icons {
        width: 215px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;

        .icon-wrap {
          position: relative;
          display: flex;

          &:after {
            content: "";
            position: absolute;
            bottom: -7px;
            left: 0;
            display: block;
            height: 3px;
            width: 33px;
            background: url('../../../../assets/images/mood-icon-shadow.svg') no-repeat;
            background-size: 29px 3px;
            background-position: center;
            transition: $standard-hover-transition;
            transform: scale(0.9);
          }

          img {
            cursor: pointer;
            transition: $standard-hover-transition;
  
            &:hover {
              transform: translateY(-2px);
            }
  
            &:active {
              transform: translateY(1px);
              transition: all .1s;
            }
          }

          &:hover {

            &:after {
              transform: scale(0.75);
            }            
          }

          &:active {

            &:after {
              transform: scale(1);
            }            
          }
        }
      }
    }
  }
}
